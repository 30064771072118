import * as React from "react";
import { list } from "./Locations.module.scss";
import useMobileDetect from "../../hooks/useMobileDetect";


export const List = (props: any) => {
    const isMobileOnly = useMobileDetect();
    return (<ul {...props} className={list} style={!isMobileOnly ? { columnCount: props.columncount } : {}}>
        {props.children}
    </ul>);
};



